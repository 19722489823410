<template>
  <div class="pa-2">
    <div class="title-resumen">
      <span class="title"> Resumen de tu ruta de aprendizaje </span>
    </div>
    <template
      v-if="
        $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'
      "
    >
      <v-timeline dense light>
        <v-timeline-item
          small
          dark
          v-for="(_module, index) in learningPath.data"
          :key="index"
          >{{ _module.degreeTitle }}
        </v-timeline-item>
      </v-timeline>
    </template>
    <ol v-else style="height:140px;">
      <li v-for="(_module, index) in learningPath.data" :key="index">
        {{ _module.degreeTitle }}
      </li>
    </ol>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['learningPath', 'selectedDegree', 'degreesCode'])
  }
}
</script>
<style scoped>
.lista li {
  display: inline;
}
.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 10px;
  margin: 2px;
  width: 10px;
}

.v-timeline-item__dot--small {
  height: 15px;
  left: -5px;
  width: 15px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 96px);
  left: 15px;
}
.title-resumen {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: 'Roboto', sans-serif !important;
  text-align: center;
}
</style>
