var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticStyle:{"position":"fixed","top":"15%","right":"0%","z-index":"1"},attrs:{"cols":"12","md":"6","lg":"7","sm":"8"}},[_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"border"},[_c('v-expansion-panel-header',{on:{"click":_vm.expansion}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"title"},[_vm._v(" Mi ruta "+_vm._s(_vm.learningPath.data.length)+"/"+_vm._s(5)+" ")])]),_c('v-col',[(
                _vm.learningPath.data.length > 0 && _vm.learningPath.data.length == 5
              )?_c('v-btn',{attrs:{"block":"","color":"#ffffff","light":""},on:{"click":function($event){_vm.createRouteDialog = true}}},[_vm._v(" Crear Tu Ruta ")]):_vm._e()],1)],1)],1),_c('v-expansion-panel-content',[_c('v-sheet',{staticClass:"niu-transparent niu-position-relative niu-width-track",attrs:{"elevation":"0"}},[_c('v-slide-group',{staticClass:"niu-transparent",attrs:{"active-class":"niu-transparent-active","show-arrows":""},model:{value:(_vm.cart),callback:function ($$v) {_vm.cart=$$v},expression:"cart"}},_vm._l((_vm.learningPath.data),function(degree){return _c('v-slide-item',{key:'degree' + degree.degreeId,scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-1 niu-card-main transparent",on:{"click":toggle}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-scale-transition',[(active)?_c('v-icon',{attrs:{"color":"black","size":"48"},domProps:{"textContent":_vm._s('mdi-close-circle-outline')},on:{"click":function($event){return _vm.removeFromPath(degree)}}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{class:_vm.classModuloCarrusel(degree, hover, toggle),attrs:{"aspect-ratio":4 / 3,"src":require('../../assets/' +
                            (degree.degreeImg
                              ? degree.degreeImg
                              : 'proximamente.png')),"lazy-src":require("../../assets/client-loader.gif")}},[_c('v-row',{staticClass:"white--text fill-height pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"align":"start"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"height":"100px"}},[(!degree.degreeHtml)?_c('div',{staticClass:"niu-carousel-route"},[_vm._v(" "+_vm._s(degree.degreeTitle)+" ")]):_c('div',{staticClass:"niu-carousel-route",domProps:{"innerHTML":_vm._s(degree.degreeTitle)}})])],1)],1)],1),_c('div',{class:_vm.barraInferiorModuloCarrusel(degree, hover)})],1)]}}],null,true)})],1)]}}],null,true)})}),1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{staticClass:"createRouteDialog",attrs:{"max-width":"450"},model:{value:(_vm.createRouteDialog),callback:function ($$v) {_vm.createRouteDialog=$$v},expression:"createRouteDialog"}},[_c('v-card',{staticClass:"mx-auto",class:{
                'niu-back-dialog-0': _vm.createRouteCarousel == 0,
                'niu-back-dialog-1': _vm.createRouteCarousel == 1,
                'niu-back-dialog-2': _vm.createRouteCarousel == 2
              },attrs:{"elevation":"24","max-width":"444"}},[_c('v-carousel',{staticStyle:{"background-color":"transparent"},attrs:{"continuous":false,"show-arrows":false,"hide-delimiters":"","delimiter-icon":"mdi-minus","height":"80vh"},model:{value:(_vm.createRouteCarousel),callback:function ($$v) {_vm.createRouteCarousel=$$v},expression:"createRouteCarousel"}},[_c('v-carousel-item',{staticClass:"pa-5 mb-5",staticStyle:{"background-color":"transparent"}},[(_vm.learningPath.data.length === 5)?_c('div',{staticStyle:{"position":"relative","top":"17%","text-align":"center"}},[_c('span',{staticClass:"display-1",staticStyle:{"font-size":"3rem !important"}},[_vm._v("¡Genial!")]),_c('p',{staticStyle:{"margin-top":"20px","font-size":"1.5rem !important"}},[_vm._v(" Has seleccionado el contenido de tu ruta de aprendizaje. ")]),_c('p',{staticStyle:{"margin-top":"20px","font-size":"1.5rem !important"}},[_vm._v(" Haz click en \"SIGUIENTE\" para ponerle nombre a tu contenido y crear tu ruta de aprendizaje. ")])]):(
                      _vm.learningPath.data.length < _vm.degreeMax[_vm.selectedDegree]
                    )?_c('div',[_c('span',{staticClass:"display-1"},[_vm._v("Faltan módulos")]),_c('p',[_vm._v(" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur voluptatibus aspernatur, ea, porro molestias unde natus alias rerum hic corrupti quaerat molestiae corporis eius commodi exercitationem eum rem odio maiores! ")])]):_vm._e()]),_c('v-carousel-item',{staticStyle:{"background-color":"transparent"}},[_c('RouteResume'),_c('v-text-field',{attrs:{"label":"","placeholder":"Nombre de la Ruta","filled":"","color":"#ffffff","required":"","height":"20px"},model:{value:(_vm.degreeTitle),callback:function ($$v) {_vm.degreeTitle=$$v},expression:"degreeTitle"}}),(_vm.creatingRoute)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1):_vm._e()],1),_c('v-carousel-item',{staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center pa-7"},[_c('span',{staticClass:"display-1",staticStyle:{"text-align":"center","margin-top":"30px"}},[_vm._v(" ¡Felicitaciones! ")]),_c('span',{staticClass:"display-1",staticStyle:{"text-align":"center","margin-top":"30px","font-size":"1.5rem !important"}},[_vm._v(" ¡Has creado exitosamente tu ruta de aprendizaje! ")]),_c('span',{staticClass:"display-1",staticStyle:{"text-align":"center","margin-top":"30px","font-size":"1.5rem !important"}},[_vm._v(" Bienvenid@ a la "),_c('br'),_vm._v("\"re-evolución\" educativa. ")]),_c('v-btn',{staticClass:"niu-btn-progreso",attrs:{"to":"/progreso"}},[_vm._v(" Ir a \"Mis rutas\" ")])],1)])],1),_c('div',{staticClass:"d-flex justify-end pa-3"},[(_vm.createRouteCarousel !== 2)?_c('v-btn',{attrs:{"disabled":_vm.learningPath.data.length < _vm.degreeMax[_vm.selectedDegree],"color":"success"},on:{"click":function($event){_vm.createRouteCarousel === 0
                      ? _vm.createRouteCarousel++
                      : _vm.createRouteCarousel--}}},[_vm._v(_vm._s(_vm.createRouteCarousel === 0 ? 'Siguiente' : 'Volver'))]):_vm._e(),(_vm.createRouteCarousel === 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmCreateRoute()}}},[_vm._v("Crear ruta")]):_vm._e(),(_vm.createRouteCarousel === 2)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeDialog}},[_vm._v("Cerrar")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }