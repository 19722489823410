<template>
  <v-row justify="space-around" align="center">
    <v-col cols="10">
      <v-sheet elevation="10" class="py-4 px-1">
        <v-chip-group
          v-model="filtro"
          column
          active-class="niu-chip-active"
          multiple
          style="color:white !important; width"
        >
          <template v-for="(area, index) in areaconocimiento">
            <!-- centrar el texto rodrigo-->
            <v-chip
              :key="area.code + '_' + index"
              style="height: 45px; display: flex; word-spacing: 1pt; "
              @click="chipSeleccionado({ area, index })"
              :class="classBgChip(area)"
            >
              <v-avatar left>
                {{ area.cantidad }}
              </v-avatar>

              <label>{{ area.title }}</label>
            </v-chip>
          </template>
        </v-chip-group>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'BotoneraArea',
  props: {
    areaconocimiento: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      filtro: []
    }
  },
  methods: {
    chipSeleccionado (area, index) {
      this.$emit('chipSeleccionado', { area, index })
    },
    classBgChip (area) {
      switch (area.name) {
        case 'emprendimientonegocio':
          return { 'niu-background-solid-emprendimientonegocio': true }
        case 'conocimientotecnico':
          return { 'niu-background-solid-conocimientotecnico': true }
        case 'desarrollofisico':
          return { 'niu-background-solid-desarrollofisico': true }
        case 'desarrollopersonal':
          return { 'niu-background-solid-desarrollopersonal': true }
        case 'comunicacion':
          return { 'niu-background-solid-comunicacion': true }
        case 'liderazgo':
          return { 'niu-background-solid-liderazgo': true }
        default:
          return { '': true }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
$fondo: white;
$fondo_card: black;
$color_niufleex: #4d1552;
$fondo_explora: #dcdcdc;
$bg_btn_explora: #a4a2a2;
$emprendimientonegocio: #FFB92E;
$liderazgo: #C10000;
$desarrollofisico: #FC3A72;
$desarrollopersonal: #8AC445;
$conocimientotecnico: #40CCB1;
$comunicacion: #354CF9;

.v-chip--active:hover::before,
.v-chip--active::before {
  opacity: 0 !important;
}
.v-chip--active {
  background-color: $color_niufleex;
}
.v-chip:not(.v-chip--active) {
  background: $bg_btn_explora !important;
  color: white !important;
}
.v-chip:not(.v-chip--active) .v-avatar {
  background: $bg_btn_explora !important;
}
.niu-color.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.niu-color.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.niu-color.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.niu-color.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.niu-color.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.niu-color.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
</style>
