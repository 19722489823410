<template>
  <v-row class="mt-16">
    <v-col>
      <v-row>
        <v-col>
          <v-row justify="center">
            <v-col cols="1">
              <v-img
                class="formato-imagen"
                src="../assets/iconos/icono_universo.png"
                aspect-ratio="1/1"
                max-width="180px"
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col style="text-align:center">
              <h1 class="niu-text-niufleex2">Universo de Conocimiento</h1>
              <!-- <small>Áreas</small> -->
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center">
            <h1 class="niu-text-niufleex-area">Áreas</h1>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5"> </v-col>
            <v-col sm="7" md="7" class="d-none d-sm-flex"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <BotoneraArea
        @chipSeleccionado="chipSeleccionado"
        :areaconocimiento="areaconocimiento"
        :filtro.sync="filtro"
      ></BotoneraArea>
      <v-row justify="center">
        <CarroRuta></CarroRuta>
      </v-row>
      <template v-if="filtrosChip.length > 0">
        <v-row v-for="(area, index) in filtrosChip" :key="index">
          <v-col>
            <v-row justify="center" class="mb-0 pb-0">
              <v-col cols="12" md="8" sm="10" class="mb-0 pb-0">
                <v-card elevation="15" :class="classBgChip(area)">
                  <v-card-title
                    style="color:white; text-align:center;margin-block-end: 0;"
                    justify="center"
                  >
                    <p style="width: 100%;">
                      <strong>{{ area.title }}</strong>
                    </p>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="elevation-15 mt-0 pt-0">
              <keep-alive>
                <CarouselConocimiento
                  v-if="
                    $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm'
                  "
                  :modulos="filterModulos(area, index)"
                ></CarouselConocimiento>
                <CarouselConocimientoMobile
                  v-else
                  :modulos="filterModulos(area, index)"
                ></CarouselConocimientoMobile>
              </keep-alive>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <v-sheet class="transparent" light>
              <h3 style="text-align:center;">Selecciona una de las áreas</h3>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
// import Degree from '../components/universo/degree.vue'
import BotoneraArea from '../components/universo/botoneraArea.vue'
import CarroRuta from '../components/aprendizaje/CarroRuta.vue'
import CarouselConocimiento from '../components/universo/carouselConocimiento.vue'
import CarouselConocimientoMobile from '../components/universo/carouselConocimientoMobile.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    // Degree,
    CarroRuta,
    CarouselConocimiento,
    CarouselConocimientoMobile,
    BotoneraArea
  },
  async created () {
    console.group('created')
    let areaconocimiento = (
      await this.$http.get('/areasconocimientos/universo')
    ).data
    this.SET_AREAS(areaconocimiento)
    this.areaconocimientoUniverso = areaconocimiento
    this.modulos = (await this.$http.get('/universos/track')).data

    // console.log(this.modulos)
    this.areaconocimiento = (
      await this.$http(`/AreasConocimientos/areasUniverso`)
    ).data
    for (let index = 0; index < this.areaconocimiento.length; index++) {
      this.$set(
        this.areaconocimiento[index],
        'name',
        this.areaconocimientoUniverso.find(
          ele => this.areaconocimiento[index].id == ele.id
        ).name
      )
    }

    console.groupEnd('created')
  },
  mounted () {
    this.changeTabs()
  },
  computed: {
    ...mapState([
      'KnowledgeAreas',
      'learningPath',
      'universe',
      'degreeSelected',
      'degrees'
    ])
  },
  data () {
    return {
      selectedTrack: null,
      changeTrackDialog: {
        show: false,
        text: 'Al cambiar de track, la ruta seleccionada...',
        showContinue: true
      },
      lastSelected: -1,
      notEnabled: false,
      areaconocimiento: null,
      modulos: [],
      areaconocimientoUniverso: [],
      filtro: [],
      filtrosChip: []
    }
  },
  methods: {
    ...mapMutations([
      'SET_AREAS',
      'SET_TRACK',
      'SET_MASTERTRACK',
      'CLEAR_learningPathData'
    ]),
    //eslint-disable-next-line
    chipSeleccionado ({ area, index }) {
      // console.log(area, [...this.filtrosChip])
      let positionIndex = null
      let filtro = false
      this.filtrosChip.forEach((ele, i) => {
        if (ele.id == area.area.id) {
          positionIndex = i
          filtro = true
        }
      })

      // console.log(area, [...this.filtrosChip], filtro)

      if (filtro) {
        this.filtrosChip.splice(positionIndex, 1)
      } else {
        this.filtrosChip.unshift(area.area)
      }
      // console.log(area, [...this.filtrosChip])
    },
    //eslint-disable-next-line
    filterModulos (id, index) {
      let modulos = this.modulos.filter(ele => ele.areaconocimientoId == id.id)

      return modulos
    },
    changeTabs () {
      if (this.lastSelected == -1) {
        this.CLEAR_learningPathData()
        this.lastSelected = this.selectedTrack
      }

      this.$store.commit('SET_CURRENT_TRACK', this.selectedTrack)
      if (
        (this.learningPath.data.length > 0 &&
          this.lastSelected != this.selectedTrack) ||
        this.selectedTrack == 2 ||
        this.selectedTrack == 3 ||
        this.selectedTrack == 1
      ) {
        this.changeTrackDialog.show = true

        //codigo de bloqueo
        this.revisionTabs()
        //fin codigo de bloqueo
      } else {
        this.changeTrackDialog.show = false
        this.lastSelected = this.selectedTrack
      }
    },
    continuar () {
      this.changeTrackDialog.show = false
      this.lastSelected = this.selectedTrack
      this.$store.commit('FLUSH_ROUTE')
    },
    revisionTabs () {
      switch (this.selectedTrack) {
        case 0:
          this.changeTrackDialog.text =
            'Al cambiar de track, la ruta seleccionada...'
          this.changeTrackDialog.showContinue = true
          break
        case 1:
          // this.changeTrackDialog.text =
          //   'Al cambiar de track, la ruta seleccionada...'
          // this.changeTrackDialog.showContinue = true
          this.changeTrackDialog.text = 'Próximamente ...'
          this.changeTrackDialog.showContinue = false
          break
        case 2:
          this.changeTrackDialog.text = 'Próximamente   ...'
          this.changeTrackDialog.showContinue = false
          break
        case 3:
          this.changeTrackDialog.text = 'Próximamente  ...'
          this.changeTrackDialog.showContinue = false
          break
      }
    },
    classBgChip (area) {
      switch (area.name) {
        case 'emprendimientonegocio':
          return { 'niu-background-solid-emprendimientonegocio': true }
        case 'conocimientotecnico':
          return { 'niu-background-solid-conocimientotecnico': true }
        case 'desarrollofisico':
          return { 'niu-background-solid-desarrollofisico': true }
        case 'desarrollopersonal':
          return { 'niu-background-solid-desarrollopersonal': true }
        case 'comunicacion':
          return { 'niu-background-solid-comunicacion': true }
        case 'liderazgo':
          return { 'niu-background-solid-liderazgo': true }
        default:
          return { '': true }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
$fondo: white;
$fondo_card: black;
$color_niufleex: #4d1552;
$fondo_explora: #dcdcdc;
$bg_btn_explora: #d3d3d3;
$emprendimientonegocio: #FFB92E;
$liderazgo: #C10000;
$desarrollofisico: #FC3A72;
$desarrollopersonal: #8AC445;
$conocimientotecnico: #40CCB1;
$comunicacion: #354CF9;
.v-chip--active:hover::before,
.v-chip--active::before {
  opacity: 0 !important;
}
.v-chip--active {
  background-color: $color_niufleex;
}
.v-chip:not(.v-chip--active) {
  background: $bg_btn_explora !important;
  color: white !important;
}
.v-chip:not(.v-chip--active) .v-avatar {
  background: $bg_btn_explora !important;
}
.niu-text-niufleex {
  color: #4d1552;
}
.niu-text-niufleex-area {
  color: #959595;
  font-size: 1.5rem;
  margin-top: -2rem;
}
.niu-text-niufleex2 {
  color: #4d1552;
  font-size: 2em;
  font-weight: bold;
}
.formato-imagen {
  width: 120px;
  height: 120px;
  padding-top: 2rem;
  margin-left: -0.7rem;
  margin-top: 70px;
  margin-right: 2rem;
}
.position {
  position: relative;
  top: -20px;
  left: 300px;
}
.circulo {
  width: 30px;
  height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #5cb85c;
  position: relative;
  top: -20px;
}

.theme--dark.v-sheet.niu-gradient,
.theme--dark.v-sheet.niu-transparent {
  background-color: black !important;
}
span.v-stepper__step__step.niu-black,
.v-application--is-ltr .v-stepper--vertical .v-stepper__step__step {
  color: white;
  background-color: black;
  font-size: 1em;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .route-path {
    background-color: transparent;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 65vw;
    left: 17vw;
    position: fixed;
    margin: 0 auto;
    z-index: 4;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .createRouteDialog {
      background-color: steelblue !important;
    }
    .route-module--row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .modules--slides {
    min-width: 300px;
    width: 100%;
    margin-left: 17%;
  }
  .route-path {
    background-color: transparent;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 65vw;
    left: 17vw;
    position: fixed;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    z-index: 4;
    .createRouteDialog {
      background-color: steelblue !important;
    }
    .route-module--row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .universe-tabs {
    font-size: 0.8em !important;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .universe-tabs {
    font-size: 1.5em !important;
  }
}

.universe-container {
  transition: background ease-in 0.5s;
  padding-top: 3%;
  .universe-areas {
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0);
  }
  .contexto {
    padding-top: 5%;
    border-bottom-color: rgba(0, 0, 0, 0) !important;

    .tracks {
      background-color: rgba(0, 0, 0, 0);
      border: none;
    }
  }
}
.transparent-bg {
  background-color: rgba(0, 0, 0, 0) !important;
}
.v-tabs-bar {
  background: rgba(0, 0, 0, 0) !important;
}
.v-tabs-bar__content {
  background: rgba(0, 0, 0, 0) !important;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.v-application--is-ltr
  .theme--light.v-stepper--vertical
  .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(255, 255, 255, 0.35);
}

.v-tab--active .v-btn {
  background: #590d53;
  background: linear-gradient(45deg, #590d53 0%, #a52882 100%);
}
.niu-color.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.niu-color.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.niu-color.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.niu-color.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.niu-color.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.niu-color.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
</style>
