<template>
  <v-col
    cols="12"
    md="6"
    lg="7"
    sm="8"
    style="position:fixed;top: 15%;right: 0%;z-index:1;"
  >
    <v-expansion-panels focusable>
      <v-expansion-panel class="border">
        <v-expansion-panel-header @click="expansion">
          <v-row>
            <v-col>
              <span class="title">
                Mi ruta {{ learningPath.data.length }}/{{ 5 }}
              </span>
            </v-col>
            <v-col>
              <v-btn
                v-if="
                  learningPath.data.length > 0 && learningPath.data.length == 5
                "
                @click="createRouteDialog = true"
                block
                color="#ffffff"
                light
              >
                Crear Tu Ruta
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-sheet
            class="niu-transparent niu-position-relative niu-width-track"
            elevation="0"
          >
            <v-slide-group
              v-model="cart"
              class="niu-transparent"
              active-class="niu-transparent-active"
              show-arrows
            >
              <v-slide-item
                v-for="degree in learningPath.data"
                :key="'degree' + degree.degreeId"
                v-slot:default="{ active, toggle }"
              >
                <v-card class="ma-1 niu-card-main transparent" @click="toggle">
                  <v-hover v-slot:default="{ hover }">
                    <v-row class="pa-0 ma-0" align="center" justify="center">
                      <v-scale-transition>
                        <v-icon
                          v-if="active"
                          color="black"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                          @click="removeFromPath(degree)"
                        ></v-icon>
                      </v-scale-transition>
                      <v-col class="pa-0 ma-0">
                        <v-img
                          :aspect-ratio="4 / 3"
                          :src="
                            require('../../assets/' +
                              (degree.degreeImg
                                ? degree.degreeImg
                                : 'proximamente.png'))
                          "
                          :class="classModuloCarrusel(degree, hover, toggle)"
                          lazy-src="../../assets/client-loader.gif"
                        >
                          <v-row
                            align="start"
                            class="white--text fill-height pa-0 ma-0"
                            style="height:100%"
                          >
                            <v-col class="ma-0 pa-0" height="100px">
                              <div
                                v-if="!degree.degreeHtml"
                                class="niu-carousel-route"
                              >
                                {{ degree.degreeTitle }}
                              </div>
                              <div
                                v-else
                                v-html="degree.degreeTitle"
                                class="niu-carousel-route"
                              ></div>
                            </v-col>
                          </v-row>
                        </v-img>
                      </v-col>

                      <div
                        :class="barraInferiorModuloCarrusel(degree, hover)"
                      ></div>
                    </v-row>
                  </v-hover>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <v-layout row justify-center>
            <v-dialog
              class="createRouteDialog"
              v-model="createRouteDialog"
              max-width="450"
            >
              <v-card
                elevation="24"
                max-width="444"
                class="mx-auto"
                :class="{
                  'niu-back-dialog-0': createRouteCarousel == 0,
                  'niu-back-dialog-1': createRouteCarousel == 1,
                  'niu-back-dialog-2': createRouteCarousel == 2
                }"
              >
                <v-carousel
                  v-model="createRouteCarousel"
                  :continuous="false"
                  :show-arrows="false"
                  hide-delimiters
                  delimiter-icon="mdi-minus"
                  height="80vh"
                  style="background-color:transparent;"
                >
                  <v-carousel-item
                    class="pa-5 mb-5"
                    style="background-color:transparent;"
                  >
                    <div
                      v-if="learningPath.data.length === 5"
                      style="position: relative; top: 17%; text-align: center;"
                    >
                      <span
                        class="display-1"
                        style="font-size: 3rem !important;"
                        >¡Genial!</span
                      >
                      <p style="margin-top: 20px;font-size: 1.5rem !important;">
                        Has seleccionado el contenido de tu ruta de aprendizaje.
                      </p>
                      <p style="margin-top: 20px;font-size: 1.5rem !important;">
                        Haz click en "SIGUIENTE" para ponerle nombre a tu
                        contenido y crear tu ruta de aprendizaje.
                      </p>
                    </div>
                    <div
                      v-else-if="
                        learningPath.data.length < degreeMax[selectedDegree]
                      "
                    >
                      <span class="display-1">Faltan módulos</span>
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Consequuntur voluptatibus aspernatur, ea, porro
                        molestias unde natus alias rerum hic corrupti quaerat
                        molestiae corporis eius commodi exercitationem eum rem
                        odio maiores!
                      </p>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item style="background-color:transparent;">
                    <RouteResume></RouteResume>
                    <v-text-field
                      label
                      placeholder="Nombre de la Ruta"
                      filled
                      color="#ffffff"
                      v-model="degreeTitle"
                      required
                      height="20px"
                    ></v-text-field>
                    <div v-if="creatingRoute">
                      <v-progress-linear
                        indeterminate
                        color="yellow darken-2"
                      ></v-progress-linear>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item style="background-color:transparent;">
                    <div
                      class="d-flex flex-column justify-center align-center pa-7"
                    >
                      <span
                        class="display-1"
                        style="text-align: center; margin-top: 30px;"
                      >
                        ¡Felicitaciones!
                      </span>
                      <span
                        class="display-1"
                        style="text-align: center; margin-top: 30px;font-size: 1.5rem !important;"
                      >
                        ¡Has creado exitosamente tu ruta de aprendizaje!
                      </span>

                      <span
                        class="display-1"
                        style="text-align: center; margin-top: 30px;font-size: 1.5rem !important;"
                      >
                        Bienvenid@ a la <br />"re-evolución" educativa.
                      </span>
                      <v-btn to="/progreso" class="niu-btn-progreso">
                        Ir a "Mis rutas"
                      </v-btn>
                    </div>
                  </v-carousel-item>
                </v-carousel>
                <div class="d-flex justify-end pa-3">
                  <v-btn
                    v-if="createRouteCarousel !== 2"
                    :disabled="
                      learningPath.data.length < degreeMax[selectedDegree]
                    "
                    color="success"
                    @click="
                      createRouteCarousel === 0
                        ? createRouteCarousel++
                        : createRouteCarousel--
                    "
                    >{{
                      createRouteCarousel === 0 ? 'Siguiente' : 'Volver'
                    }}</v-btn
                  >
                  <v-btn
                    v-if="createRouteCarousel === 1"
                    color="primary"
                    class="ml-2"
                    @click="confirmCreateRoute()"
                    >Crear ruta</v-btn
                  >
                  <v-btn
                    v-if="createRouteCarousel === 2"
                    color="primary"
                    @click="closeDialog"
                    >Cerrar</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>
<script>
/* eslint-disable no-empty */
import Rules from '../../services/routeRules.js'
import { mapState, mapMutations } from 'vuex'
import RouteResume from '../../components/aprendizaje/CreateRouteResume.vue'
import { EventBus } from '../../plugins/event-bus'

export default {
  components: {
    RouteResume
  },
  mounted () {
    EventBus.$on('SET_createRouteDialog', function (payLoad) {
      this.createRouteDialog = payLoad
    })
  },
  computed: {
    ...mapState([
      'KnowledgeAreas',
      'learningPath',
      'degreeMax',
      'selectedDegree'
    ])
  },
  methods: {
    ...mapMutations([
      'REMOVE_FROM_PATH',
      'ADD_TITLE',
      'SET_ALERT',
      'CLEAR_learningPathData'
    ]),

    barraInferiorModuloCarrusel (modulo, hover) {
      return {
        'niu-barra': true,
        'niu-background-gradient-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-gradient-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-gradient-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-gradient-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-gradient-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-gradient-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    classModuloCarrusel (modulo, hover) {
      return {
        'niu-rounded-4': true,
        'niu-hover': true,
        'niu-border-emprendimientonegocio-light':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-border-liderazgo-light':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-border-comunicacion-light':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-border-desarrollopersonal-light':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-border-desarrollofisico-light':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-border-conocimientotecnico-light':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    activeModulo () {},
    expansion () {
      this.cart = this.learningPath.data.length - 1
    },
    removeFromPath (modulo) {
      let ind = this.learningPath.data.findIndex(
        mod => mod.degreeId === modulo.degreeId
      )
      return this.REMOVE_FROM_PATH(ind)
    },
    confirmCreateRoute () {
      if (this.degreeTitle) {
        this.creatingRoute = true
        this.ADD_TITLE(this.degreeTitle)

        let body = {
          ...this.learningPath,
          data: [...this.learningPath.data],
          userid: this.$storeNiu.getTextOrInt('user'),
          degreeId: 0
        }
        this.txtdata = JSON.stringify(body)

        this.$http
          .post('universos/createTrack', {
            ...this.learningPath,
            data: [...this.learningPath.data],
            userid: this.$storeNiu.getTextOrInt('user'),
            degreeId: 0
          })
          .then(response => {
            response
            this.creatingRoute = false
            this.createRouteCarousel = 2
            this.CLEAR_learningPathData()
          })
          .catch(error => {
            error
          })
      } else {
        this.SET_ALERT({
          message: 'Falta el nombre de la ruta que estas creando',
          type: 'error',
          snackbar: true
        })
      }
    },
    closeDialog () {
      this.createRouteDialog = false
    }
  },
  data () {
    return {
      rules: Rules,
      createRouteCarousel: 0, //0 original
      creatingRoute: false,
      cart: null,
      createRouteDialog: false,
      degreeTitle: '',
      txtdata: ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.niu-back-dialog-0 {
  background-color: #a52882 !important;
}
.niu-back-dialog-1 {
  background-color: #a50b7a !important;
}
.niu-back-dialog-2 {
  background-color: #590d53 !important;
}
.v-application .niu-transparent-active {
  background-color: transparent !important;
  border-color: transparent;
}
.niu-with-track {
  width: 50vw;
}
// .niu-card-main {
//   width: 25vw;
//   height: 26vh;
// }
.niu-btn-progreso {
  font-size: 2rem !important;
  margin-top: 30px !important;
  color: #590d53 !important;
  font-weight: 1000 !important;
  background-color: #ffffff !important;
  height: auto !important;
}
.border {
  background-color: #590d53 !important;
  color: #ffffff !important;

  border-radius: 22px 22px 22px 22px;
  -moz-border-radius: 22px 22px 22px 22px;
  -webkit-border-radius: 22px 22px 22px 22px;
  border: 0px solid #000000;
}
.color {
  background-color: #ffffff;
}

.niu-btn-progreso:hover {
  // background-color: #ffe800  !important;
  background-color: #f39d00 !important;
  color: #fff !important;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .route-path {
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 65vw;
    left: 17vw;
    position: fixed;
    margin: 0 auto;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .createRouteDialog {
      background-color: steelblue !important;
    }
    .route-module--row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .modules--slides {
    min-width: 300px;
    width: 100%;
    margin-left: 17%;
  }
  .route-path {
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 65vw;
    left: 17vw;
    position: fixed;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    .createRouteDialog {
      background-color: steelblue !important;
    }
    .route-module--row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.niu-carousel-route {
  background-color: rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 1px #000;
  text-align: center;
  padding-top: 8px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  height: 50%;
}
</style>
